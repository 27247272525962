import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEinHausInKarnten = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet ein Haus in Kärnten?" showCalc={false} />
            <Article>
                <p>
                    Haus, der Traum! Auf der Suche nach dem passenden Eigenheim ist vor allem eines wichtig: sich
                    möglichst früh einen Überblick über das Angebot zu verschaffen und ein Gespür für regionale
                    Unterschiede auf dem Markt zu entwickeln. In den Artikeln dieser Serie widmen wir uns daher den
                    einzelnen Bundesländern und geben dir einen ersten Überblick über das dortige Angebot. Diesmal steht
                    die Steiermark auf dem Programm.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienpreise in Österreich – aktuelle Tendenzen</h2>
                <p>
                    Die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    für Immobilienkredite sind zur Zeit sehr günstig. Daher rangiert der Anstieg der Immobilienpreise in
                    Österreich seit dem vierten Quartal 2020 im zweistelligen Prozentbereich. Während er im ersten
                    Quartal 2021 12,3 % betrug, war im zweiten eine Teuerung von 11,7 % zu verzeichnen. Der Anstieg der
                    Kaufpreise für Einfamilienhäuser außerhalb Wiens sank 2021 von 12,9 % im ersten auf 11,3 % im
                    zweiten Quartal. Gebrauchte Eigentumswohnungen wurden in diesem Zeitraum hingegen um 14,3 % teurer.
                    Auffällig ist darüber hinaus, dass die Immobilienpreise außerhalb Wiens schneller wachsen als
                    innerhalb der Landeshauptstadt: Die Preise in Wien stiegen im ersten Quartal 2021 um 10,9, im
                    zweiten um 10,7 %. Zu deutlich höheren Wachstumsraten von 14 bzw. 12,8 % kam es in derselben Zeit in
                    den übrigen Bundesländern. Besonders deutlich ist dieser Unterschied auf dem Neuwohnungsmarkt:
                    Stiegen die Preise für neue Immobilien in Wien 2021 um 10,4 im ersten und von 10, 1 % zweiten
                    Quartal, so kam es in den übrigen Bundesländern zu einem Anstieg von 16,7 und 12,9 %.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Der Immobilienmarkt in Kärnten</h2>
                <p>
                    In Kärnten steigen die Preise für Immobilien und Grundstücke derzeit weniger rasant als ein anderen
                    Bundesländern. Lediglich in den Stadtgebieten von Klagenfurt und Villach überschreiten die
                    Durchschnittspreise für Baugrundstücke die 100-Euro-Marke. Selbst ein Haus mit großem Garten ist in
                    Kärnten daher noch relativ günstig und gut finanzierbar. Der Durchschnitt der Quadratmeterpreise für
                    Eigentumswohnugen bewegt sich zwischen 1 722, 84 € im ländlichen und 2 360 € im urbanen Gebiet
                    Klagenfurts.
                </p>
                <hr />

                <h2>Das kostet ein Haus in Kärnten</h2>
                <p>
                    In Klagenfurt (Stadt) verzeichnet man im Falle von Einfamilienhäusern derzeit einen
                    Quadratmeterpreis von 1.468,21 €. Ein ähnlich hohes Preisniveau weist das Stadtgebiet von Villach
                    mit 1.327,73 € auf. Etwas günstiger sind Häuser in Feldkirchen mit 1.169,84 und Wolfsberg mit
                    1.206,25 € pro Quadratmeter. Ein kleiner Tipp zum Schluss: Denk daran, neben dem Kaufpreis auch
                    sämtliche Nebenkosten einzukalkulieren, die beim Erwerb einer Immobilie anfallen.
                </p>
                <p>
                    Du hast dich für einen Wohnort entschieden und bist jetzt auf der Suche nach der passenden
                    Finanzierung für dein neues Zuhause? Nutze unseren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    oder vereinbare gleich ein kostenloses und unverbindliches Erstgespräch mit unseren
                    Finanzexpert:innen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEinHausInKarnten"}></BreadcrumbList>
            <ArticleStructuredData page={"wasKostetEinHausInKarnten"} heading={"Was kostet ein Haus in Kärnten?"} />
        </Layout>
    );
};

export default WasKostetEinHausInKarnten;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.was-kostet-ein-haus-in-karnten", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
